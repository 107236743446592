var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card shadow-sm d-flex flex-column h-100 mx-auto position-relative card-main",style:(_vm.getCardBackground(_vm.pro.style) +
        _vm.getCardBorderCSS(_vm.pro.style) +
       _vm.getCardHeight(_vm.pro.flipCardsHeight)+
        _vm.getFontFamily(_vm.pro.style) +
        _vm.ListProductMaxWidth(
          _vm.isDesktopView ? _vm.pro.productsPerPage : _vm.pro.productsPerPageMobile
        ))},[_c('div',{staticClass:"card__face card__face--front"},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"",staticStyle:{"height":"30%"},style:(_vm.getPagePadding(_vm.pro.style))},[_c('p',{staticClass:"flipCardProductTitle",staticStyle:{"-webkit-box-orient":"vertical","display":"-webkit-box","overflow":"hidden","text-overflow":"ellipsis","overflow-wrap":"break-word"},style:(_vm.getTitleStyles(_vm.pro.style) + `-webkit-line-clamp: ${_vm.calculateTitleLineClamp};`)},[_vm._v(" "+_vm._s(_vm.getProductInfoDetail(_vm.pro.useProductVariant, _vm.product, "title"))+" ")]),(_vm.product.rating && _vm.isJudgeMeInstalled)?_c('div',{staticClass:"Review",style:(_vm.getPriceColor(_vm.pro.style)),domProps:{"innerHTML":_vm._s(_vm.product.rating)}}):_vm._e()]),(
            _vm.showImage(
              _vm.getProductInfoDetail(_vm.pro.useProductVariant, _vm.product, 'image')
            )
          )?_c('div',{staticStyle:{"height":"70%"}},[(
              _vm.checkFileType(
                _vm.getProductInfoDetail(_vm.pro.useProductVariant, _vm.product, 'image')
              ) == 'video'
            )?_c('div',{staticClass:"w-100 h-100"},[_c('video',{ref:"videoRef",staticStyle:{"object-fit":"cover","object-position":"center","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.pro.style) + _vm.getImageTopBorderCSs(_vm.pro.style)),attrs:{"src":`${_vm.getProductInfoDetail(
                _vm.pro.useProductVariant,
                _vm.product,
                'image'
              )}`,"controls":""}})]):_c('div',{staticClass:"w-100 h-100"},[(
                _vm.getProductInfoDetail(
                  _vm.pro.useProductVariant,
                  _vm.product,
                  'image'
                ).startsWith('upload')
              )?_c('LazyImage',{staticStyle:{"object-fit":"cover","object-position":"center","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.pro.style) + _vm.getImageTopBorderCSs(_vm.pro.style)),attrs:{"src":'/' +
                _vm.getProductInfoDetail(_vm.pro.useProductVariant, _vm.product, 'image'),"alt":""}}):_c('LazyImage',{staticStyle:{"object-fit":"cover","object-position":"center","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.pro.style) + _vm.getImageTopBorderCSs(_vm.pro.style)),attrs:{"src":_vm.getProductInfoDetail(_vm.pro.useProductVariant, _vm.product, 'image'),"alt":""}})],1)]):_vm._e()])]),_c('div',{staticClass:"card__face card__face--back"},[_c('div',{staticClass:"h-100",style:(_vm.getPagePadding(_vm.pro.style))},[_c('div',{staticClass:"d-flex flex-column h-100",staticStyle:{"padding-bottom":"10px"}},[_c('div',{staticClass:"d-flex flex-column justify-content-between h-100"},[_c('div',{staticStyle:{"flex":"1","overflow":"hidden auto"}},[(!_vm.pro.hideDescription)?_c('div',[_c('div',{staticClass:"d-flex align-items-start mt-1",style:(_vm.limitProductDescription(
                      _vm.pro.limitDescription,
                      _vm.product.readMore
                    ))},[(
                      _vm.pro.useBulletDescription &&
                      _vm.product.bullet_description &&
                      _vm.product.bullet_description.length
                    )?_c('div',_vm._l((_vm.product.bullet_description),function(bulletPoint,index2){return _c('div',{key:index2},[_c('div',{staticClass:"d-flex align-items-start mt-1",style:(_vm.getBulletStyles(_vm.pro.style))},[_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"fa",class:_vm.pro.selectedBulletIcon})]),_c('span',{staticStyle:{"text-align":"start"}},[_vm._v(_vm._s(bulletPoint))])])])}),0):_c('div',{staticClass:"w-100",style:(`font-family:${_vm.pro.style.fontFamily};`),domProps:{"innerHTML":_vm._s(_vm.product.description)}})]),(
                    _vm.pro.limitDescription &&
                    _vm.product.description &&
                    _vm.product.description.split(' ').join('').length > 150 &&
                    false
                  )?_c('div',{},[_c('button',{staticClass:"btn",class:_vm.product.readMore ? 'mt-2' : 'mt-2',style:(_vm.getPriceColor(_vm.pro.style)),on:{"click":function($event){_vm.product.readMore = !_vm.product.readMore}}},[(_vm.product.readMore)?_c('span',[_vm._v(_vm._s(_vm.translateText("Less info")))]):_c('span',[_vm._v(_vm._s(_vm.translateText("More info"))+" ")])])]):_vm._e()]):_vm._e()]),_c('div',{},[_c('div',{staticClass:"d-flex flex-column h-100 justify-content-end"},[_c('div',{staticClass:"d-flex align-items-center",style:('justify-content:' + _vm.pro.style.textAlign + ';')},[(_vm.product.price && !_vm.isHidePrice)?_c('p',{staticClass:"m-0 p-0 mr-2",style:(_vm.getPriceColor(_vm.pro.style))},[(_vm.CurrencySign && _vm.currencyPos == 'left')?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e(),_vm._v(_vm._s(_vm.getProductInfoDetail(_vm.pro.useProductVariant, _vm.product, "price"))),(_vm.CurrencySign && _vm.currencyPos == 'right')?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e()]):_vm._e(),(
                    _vm.product.compare_at_price &&
                    _vm.product.price !== _vm.product.compare_at_price &&
                    !_vm.isHidePrice
                  )?_c('p',{staticClass:"m-0 p-0",style:(_vm.getPriceColor(_vm.pro.style))},[_c('s',[(_vm.CurrencySign && _vm.currencyPos == 'left')?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e(),_vm._v(_vm._s(_vm.product.compare_at_price)),(_vm.CurrencySign && _vm.currencyPos == 'right')?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e()])]):_vm._e()]),(_vm.product.detail_link)?_c('div',{staticClass:"mt-3"},[(_vm.pro.useProductVariant && _vm.product && _vm.product.variants)?_c('div',{staticClass:"pb-2"},[_c('VaraintSelectComp',{attrs:{"pro":_vm.pro,"product":_vm.product}})],1):_vm._e(),(_vm.pro.useAddToCart)?_c('button',{staticClass:"ButtonHover",style:(_vm.getProductButtonCss(_vm.pro.style)),attrs:{"disabled":_vm.disableAddtoCartBtn},on:{"click":function($event){return _vm.AddToCart(_vm.product, _vm.index)}}},[(!_vm.product.loading)?_c('span',[_vm._v(_vm._s(_vm.pro.useAddToCartText || "Add to Cart"))]):_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]):_c('button',{staticClass:"ButtonHover",style:(_vm.getProductButtonCss(_vm.pro.style)),on:{"click":function($event){return _vm.goToURLClicked(_vm.product.detail_link, _vm.product.id)}}},[(!_vm.product.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.pro.buttonText))]):_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])]):_vm._e()])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }